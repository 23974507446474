import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@/store';
import { FETCH_IS_ENTERPRISE } from '@/store/features/user/user.saga';

const useEnterpriseAllowList = () => {
  const isHasPermissions = useSelector((root: RootState) => root.user.isEnterpriseUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isHasPermissions === undefined) {
      dispatch({ type: FETCH_IS_ENTERPRISE });
    }
  }, [isHasPermissions, dispatch]);

  return {
    isHasPermissions,
  };
};

export default useEnterpriseAllowList;
