import firebase from 'firebase/app';
import User from './user';
import Groups from './groups';
import Invitation from './invitation';
import GroupUsersPermissions from './group-users-permissions';
import Credentials from './credentials';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_CLIENT_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CLIENT_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_CLIENT_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CLIENT_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CLIENT_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_CLIENT_APP_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
class FirebaseApi {
  user: User;
  credentials: Credentials;
  groups: Groups;
  groupUsersPermissions: GroupUsersPermissions;
  invitation: Invitation;
  constructor() {
    this.user = new User();
    this.credentials = new Credentials();
    this.groups = new Groups();
    this.groupUsersPermissions = new GroupUsersPermissions();
    this.invitation = new Invitation();
  }
}

export const firebaseApi = new FirebaseApi();
