import Routes from './routes/Routes';

import { Provider } from 'react-redux';
import { store } from './store';
import { createTheme, ThemeProvider } from '@material-ui/core';

const theme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        boxShadow: 'none',
        padding: '10px',
        marginBottom: '10px',
        minHeight: '100%',
      },
    },
  },
});
function App() {
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;
