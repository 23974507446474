import { Redirect, Route, RouteProps } from 'react-router-dom';
import React from 'react';

// @ts-ignore
function PublicRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (!isAuthenticated ? children : <Redirect to={'/profile'} />)}
    />
  );
}

export default PublicRoute;
