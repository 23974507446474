import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: '',
  title: '',
  description: '',
  isOpen: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    set: (state, action: { payload: { type: string; title?: string; description?: string } }) => {
      state.title = action.payload.title || '';
      state.description = action.payload.description || '';
      state.type = action.payload.type;
      state.isOpen = true;
    },
    remove: (state) => {
      state.description = '';
      state.title = '';
      state.type = '';
      state.isOpen = false;
    },
  },
});

export default notificationsSlice;
