import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import Loader from '@/components/Loader';
import SidebarWrapper from '@/layouts/SidebarWrapper';
import Notifications from '@/components/Notifications';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import RouterUrlHelper from './RouterUrlHelper';

/**
 * Hooks
 */
import useAuth from '@/hooks/useAuth';

const HomePage = lazy(() => import('@/pages/Home'));
const MyAccount = lazy(() => import('@/pages/MyAccount'));
const MyGroups = lazy(() => import('@/pages/MyGroups'));
const Login = lazy(() => import('@/pages/Login'));
const CreateAccount = lazy(() => import('@/pages/SignUp'));
const Plans = lazy(() => import('@/pages/Plans'));
const EnterpriseUsers = lazy(() => import('@/pages/EnterpriseUsers/EnterpriseUsers.page'));

const routes = [
  {
    path: RouterUrlHelper.urlMap.home.path,
    component: HomePage,
    exact: true,
  },
  {
    path: RouterUrlHelper.urlMap.profile.path,
    component: MyAccount,
    exact: true,
    withSidebar: true,
  },
  {
    path: RouterUrlHelper.urlMap.groups.path,
    component: MyGroups,
    exact: true,
    withSidebar: true,
  },
  {
    path: RouterUrlHelper.urlMap.createAccount.path,
    component: CreateAccount,
    exact: true,
  },
  {
    path: RouterUrlHelper.urlMap.plans.path,
    component: Plans,
    exact: true,
    withSidebar: true,
  },
  {
    path: RouterUrlHelper.urlMap.enterpriseUsers.path,
    component: EnterpriseUsers,
    exact: true,
    withSidebar: true,
  },
  {
    path: '/',
    component: () => <Redirect from="*" to={RouterUrlHelper.urlMap.login.path} />,
    exact: true,
  },
];

const ProtectedRoutes = () => (
  <Switch>
    <Suspense fallback={<Loader />}>
      {routes.map(({ withSidebar, component: Component, path, exact }) => (
        <Route path={path} key={path} exact={exact}>
          {withSidebar ? (
            <SidebarWrapper>
              <>
                <Notifications />
                <Component />
              </>
            </SidebarWrapper>
          ) : (
            <Component />
          )}
        </Route>
      ))}
    </Suspense>
  </Switch>
);

const Routes = () => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return (
      <div className={'flex h-full justify-center items-center'}>
        <Spin />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PublicRoute path={RouterUrlHelper.urlMap.login.path} isAuthenticated={isAuthenticated}>
            <Notifications />
            <Login />
          </PublicRoute>
          <PrivateRoute path="/" isAuthenticated={isAuthenticated}>
            <ProtectedRoutes />
          </PrivateRoute>
          <Redirect to={'/'} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
