import firebase from 'firebase/app';
import 'firebase/auth';

export const getCurrentUser = () => {
  return firebase.auth().currentUser;
};

export async function getUserToken() {
  return await firebase.auth().currentUser?.getIdToken();
}

export async function getUserEmail() {
  return firebase.auth().currentUser?.email as string;
}
