import { configureStore } from '@reduxjs/toolkit';
import groupsSlice from './features/groups/groupsSlice';
import userSlice from './features/user/userSlice';
import notificationsSlice from './features/notifications/notificationsSlice';
import authSlice from './features/auth/authSlice';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './root.saga';
/**
 * Init middlewares
 */
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    groups: groupsSlice.reducer,
    user: userSlice.reducer,
    notifications: notificationsSlice.reducer,
  },
  middleware: [sagaMiddleware],
});

export type RootState = ReturnType<typeof store.getState>;

sagaMiddleware.run(rootSaga);

store.dispatch(authSlice.actions.authVerify());
