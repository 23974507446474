import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';
import notificationsSlice from '../../store/features/notifications/notificationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';

interface AlertRefProps extends AlertProps {
  children: any;
}

const Alert = React.forwardRef<any, AlertRefProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notifications = () => {
  const dispatch = useDispatch();
  const notification = useSelector<RootState, RootState['notifications']>(
    (state) => state.notifications,
  );

  return (
    <Snackbar
      open={!!notification.isOpen}
      autoHideDuration={3000}
      onClose={() => {
        // This onClose works only if set property autoHideDuration
        dispatch(notificationsSlice.actions.remove());
      }}
    >
      <Alert
        onClose={() => {
          dispatch(notificationsSlice.actions.remove());
        }}
        severity={notification.type as Color}
      >
        {notification.title && <h3 className={'color color-white'}>{notification.title}</h3>}
        {notification.description && <p>{notification.description}</p>}
      </Alert>
    </Snackbar>
  );
};

export default Notifications;
