import { CircularProgress } from '@material-ui/core';

const Loader = () => {
  return (
    <>
      <CircularProgress />
    </>
  );
};

export default Loader;
