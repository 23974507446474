import firebase from 'firebase/app';
import 'firebase/database';
import { Tables } from '../../common/constants';
import { Dictionary, IGroups } from '../../common/common-types';

export default class Groups {
  findByIds(ids: string[]): Promise<any> {
    return Promise.all(
      ids.map((id: string) => {
        return firebase
          .database()
          .ref(Tables.Groups + '/' + id)
          .once('value')
          .then((ss) => {
            if (!ss.exists()) return null;
            return {
              ...ss.val(),
              groupId: id,
            };
          });
      }),
    );
  }

  updateChildren(groupId: string, children: any[]): Promise<any> {
    return firebase.database().ref(Tables.Groups).child(groupId).update({
      children: children,
    });
  }

  updateRootLevelGroup(uid: string, groupId: string, groups: Dictionary<IGroups>): Promise<any> {
    const updateTheRootLevelGroup = firebase.database().ref(Tables.Groups).child(groupId).remove();

    const updateTheGroupsInsideUser = firebase.database().ref(Tables.Users).child(uid).update({
      groups: groups,
    });

    return Promise.all([updateTheRootLevelGroup, updateTheGroupsInsideUser]);
  }

  metaByGroupId(groupId: string): Promise<any> {
    return firebase
      .database()
      .ref(Tables.GroupMeta)
      .child(groupId)
      .once('value')
      .then((ss) => {
        if (!ss.exists()) return null;
        return ss.val();
      });
  }

  /**
   * @param groupId
   * @return [userId, userId]
   */
  getGroupUsers = async (groupId: string) => {
    return firebase
      .database()
      .ref(Tables.GroupUsers)
      .child(groupId)
      .once('value')
      .then((ss) => {
        if (!ss.exists()) return null;
        return Object.keys(ss.val().users);
      });
  };
}
