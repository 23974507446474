export const routerUrlHelper = {};

class RouterUrlHelper {
  public static urlMap = {
    root: {
      path: '/',
    },
    login: {
      path: `/login`,
    },
    home: {
      path: `/home`,
    },
    profile: {
      path: `/profile`,
    },
    groups: {
      path: `/groups`,
    },
    createAccount: {
      path: `/createaccount`,
    },
    plans: {
      path: `/plans`,
    },
    enterpriseUsers: {
      path: `/enterprise-users`,
    },
    // Example
    mainRoute: {
      path: `/mainRoute`,
      innerRoute: `/mainRoute/create`,
      innerRouteWithParam: `/mainRoute/create/:id`,
      getDetails: (id: string) => `/mainRoute/${id}`,
    },
  };
}
export default RouterUrlHelper;
