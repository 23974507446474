// @ts-nocheck
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { IUserData } from '../../common/common-types';
import { Tables, API_BASE_URL } from '../../common/constants';
import axios from 'axios';

export default class User {
  findUser(uuid: string): Promise<any> {
    return firebase
      .database()
      .ref(Tables.Users)
      .child(uuid)
      .once('value')
      .then((ss) => {
        if (!ss.exists()) {
          return null;
        }
        return ss.val();
      });
  }

  findByIds(ids: string[]): Promise<any> {
    return Promise.all(
      ids.map((id: string) => {
        return firebase
          .database()
          .ref(Tables.Users + '/' + id)
          .once('value')
          .then((ss) => {
            if (!ss.exists()) return null;
            return ss.val();
          });
      }),
    );
  }

  getFirebaseUserInfo(): Promise<any> {
    const user = firebase.auth().currentUser;

    if (!user) {
      return Promise.reject(new Error('Not logged in yet'));
    }

    return Promise.all([
      user.getIdToken(),
      user.getIdTokenResult().then((res) => new Date(res.expirationTime)),
    ]).then((tuple) => {
      const [idToken, expirationTime] = tuple;

      return {
        idToken,
        expirationTime,
        displayName: user.displayName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL,
        providerId: user.providerId,
        uid: user.uid,
      };
    });
  }

  create(data: any): Promise<any> {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(data.email, data.password)
      .then(({ user }: any) => {
        const uid = user.uid;
        const email = data.email;
        const username = data.firstName + ' ' + data.lastName;

        return user.sendEmailVerification().then(() => {
          return this.updateProfile({
            uid: uid,
            email: email,
            displayName: username,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            companyName: data.companyName,
            companySize: data.companySize,
            industry: data.industry,
            acceptTermsDate: new Date(),
          });
        });
      });
  }
  removeUser(userId: any): Promise<any> {
    return firebase.database().ref(Tables.Users).child(userId).remove();
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<any> {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  signOut(): Promise<any> {
    return firebase.auth().signOut();
  }

  deleteUserFromAuth(): Promise<any> {
    return firebase.auth().currentUser.delete();
  }

  sendPasswordResetEmail(email: string): Promise<any> {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  updateProfile(userData: IUserData): Promise<any> {
    const uid = userData.uid;

    if (!uid) {
      throw new Error('Not valid user id');
    }

    return firebase
      .database()
      .ref(Tables.Users)
      .child(uid)
      .update({
        uid: uid,
        username: userData.firstName + ' ' + userData.lastName,
        phone: userData.phone,
        company_name: userData.companyName,
        company_size: userData.companySize,
        industry: userData.industry,
        ...(userData.displayName && { displayName: userData.displayName }),
        ...(userData.acceptTermsDate && { accept_terms_date: userData.acceptTermsDate }),
      });
  }

  changePermissions(groupId: string, email: string, newRoleCode: string) {
    return this.findByEmail(email).then((user) => {
      const path = Tables.Users + '/' + user.uid + '/groups/' + groupId;
      const value = { role: newRoleCode };
      return axios
        .post(API_BASE_URL + 'updates', {
          path: path,
          value: value,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  removeFromGroup(groupId: string, email: string) {
    return this.findByEmail(email).then((user) => {
      const userPath = Tables.Users + '/' + user.uid + '/groups/' + groupId;
      const groupPath = Tables.GroupUsers + '/' + groupId + '/users/' + user.uid;

      return axios
        .post(API_BASE_URL + 'removes', {
          firstPath: userPath,
          secondPath: groupPath,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
}
