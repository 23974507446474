import axios, { AxiosError, AxiosResponse } from 'axios';
import { getHeaders } from './CloudFunctions/helpers';
import { getUserToken } from './AuthService';
import { User } from './CloudFunctions';

axios.defaults.baseURL = (
  process.env.REACT_APP_IS_LOCAL_CLOUD_FUNCTIONS === 'true'
    ? process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_LOCALHOST
    : process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS
) as string;

interface IInvitationSendArgs {
  groupId: string;
  senderUid: string;
  senderName: string | null;
  emails: string;
}

interface IInviteResponse {
  data?: { code: string; emailSent: boolean };
  error_code: number;
  message?: string;
}

export const axiosResponseHandler = (res: Promise<AxiosResponse<any>>) =>
  res
    .then((res: any) => {
      return res.data;
    })
    .catch((e: AxiosError<{ error_code: string; message: string }>) => {
      console.log('--- err', e);
      return e.response?.data;
    });

class Invitation {
  async send({ groupId, senderName, senderUid, emails }: IInvitationSendArgs) {
    const data = {
      groupId,
      senderUid,
      senderName,
      emails,
    };
    const res = axios.post<IInviteResponse>('/invitationSend', data, await getHeaders());
    return res
      .then((res: AxiosResponse<IInviteResponse>) => {
        return res.data;
      })
      .catch((e: AxiosError<{ error_code: string; message: string }>) => {
        throw Error(e.response?.data?.message || 'Something went wrong');
      });
  }
}

class UserCard {
  async add({
    cardNumber,
    expMonth,
    expYear,
    cvv,
  }: {
    cardNumber: string;
    expMonth: string;
    expYear: string;
    cvv: string;
  }) {
    const data = {
      cardNumber,
      expMonth,
      expYear,
      cvv,
    };
    const response = axios.post('/userAddCard', data, await getHeaders());
    return axiosResponseHandler(response);
  }

  async remove({ pmId }: { pmId: string }) {
    const data = {
      pmId,
    };
    const response = axios.post('/userRemoveCard', data, await getHeaders());
    return axiosResponseHandler(response);
  }

  async list() {
    const response = axios.get('/userListCard', await getHeaders());
    return axiosResponseHandler(response);
  }
}

class Group {
  async groupUpgrade({ groupId }: { groupId: string }) {
    const res = axios.post('/groupUpgrade', { groupId }, await getHeaders());
    return res
      .then((res: any) => res)
      .catch((e: AxiosError<{ error_code: string; message: string }>) => {
        return e.response?.data;
      });
  }

  async deleteUser({ groupId, memberUid }: { groupId: string; memberUid: string }) {
    const res = axios.post('/groupDeleteUser', { groupId, memberUid }, await getHeaders());
    return res
      .then((res: any) => res)
      .catch((e: AxiosError<{ error_code: string; message: string }>) => {
        return e.response?.data;
      });
  }

  async changeIndividualPermission({
    groupId,
    permission,
    uid,
  }: {
    groupId: string;
    permission: string;
    uid: string;
  }) {
    const userToken = await getUserToken();

    const res = axios.post(
      '/groupChangeIndividualPermission',
      {
        groupId,
        permission,
        memberUid: uid,
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      },
    );

    return res
      .then((res: any) => res)
      .catch((e: AxiosError<{ error_code: string; message: string }>) => {
        return e.response?.data;
      });
  }
}

class CloudFunctionsService {
  invitation = new Invitation();
  group = new Group();
  userCard = new UserCard();
  user = new User();

  constructor() {
    this.invitation = new Invitation();
    this.group = new Group();
    this.userCard = new UserCard();
    this.user = new User();
  }
}

const cloudFunctionsInstance = new CloudFunctionsService();

export default cloudFunctionsInstance;
