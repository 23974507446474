import firebase from 'firebase/app';
import 'firebase/auth';

import { put, takeLatest, call } from 'redux-saga/effects';
import authSlice from './authSlice';
import { firebaseApi } from '../../../services/Firebase/firebase';
import { ISignInResponse } from '../../../types/firebase.types';
import notificationsSlice from '../notifications/notificationsSlice';

const AUTH_VERIFY = `${authSlice.name}/${authSlice.caseReducers.authVerify.name}`;
const SIGN_IN = `${authSlice.name}/${authSlice.caseReducers.signIn.name}`;
const SIGN_OUT = `${authSlice.name}/${authSlice.caseReducers.signOut.name}`;

function onAuthStateChanged() {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        resolve(user);
      } else {
        reject(new Error('Auth failed!'));
      }
    });
  });
}

export function* authVerify() {
  try {
    yield call(onAuthStateChanged);
    yield put(authSlice.actions.authSuccess());
  } catch (e: any) {
    yield put(authSlice.actions.authFailed({}));
  }
}

export function* signIn(action: { type: string; payload: { email: string; password: string } }) {
  const USER = firebaseApi.user;

  try {
    const res: ISignInResponse = yield call(
      USER.signInWithEmailAndPassword,
      action.payload.email,
      action.payload.password,
    );

    yield put(authSlice.actions.authVerify());
  } catch (e: any | firebase.FirebaseError) {
    yield put(authSlice.actions.authFailed({ errorMsg: e.message }));
  }
}

export function* signOut(action: { type: string; payload: any }) {
  const USER = firebaseApi.user;

  try {
    yield call(USER.signOut);
    yield put(authSlice.actions.authVerify());
  } catch (e: any) {
    yield put(authSlice.actions.authFailed({}));
    yield put(
      notificationsSlice.actions.set({ description: e.message, title: 'Error', type: 'error' }),
    );
  }
}

export function* authSaga() {
  yield takeLatest(AUTH_VERIFY, authVerify);
  yield takeLatest(SIGN_IN, signIn);
  yield takeLatest(SIGN_OUT, signOut);
}
