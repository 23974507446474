import firebase from 'firebase/app';
import 'firebase/database';
import { Tables, API_BASE_URL } from '../../common/constants';
import User from './user';
import Groups from './groups';
import axios from 'axios';
import { TsbGroupInvitationItem } from './types';
export default class Invitation {
  user: User;

  groups: Groups;
  groupInvitationRef: firebase.database.Reference;
  constructor() {
    this.user = new User();
    this.groups = new Groups();
    this.groupInvitationRef = firebase.database().ref(Tables.GroupInvitation);
  }

  getPendingUsersByGroupId(groupId: string): Promise<TsbGroupInvitationItem[] | any[]> {
    return this.groupInvitationRef
      .child(groupId)
      .child('pending')
      .once('value')
      .then((ss) => {
        if (!ss.exists()) return [];
        return Object.entries(ss.val() as TsbGroupInvitationItem).map(([k, v]) => {
          return {
            ...v,
            email: decodeURIComponent(k),
          };
        });
      });
  }

  encodeEmail(s: string): string {
    return encodeURIComponent(s.toLowerCase()).replace(/\./g, '%2E');
  }
  add(
    meta: any,
    sender: any,
    groupId: string,
    email: string,
    code: string,
    role: string,
  ): Promise<any> {
    const emailStr = this.encodeEmail(email);
    const data = {
      code,
      role: role || null,
      groupName: meta.name,
      senderId: sender.uid, // attention here
      senderName: sender.username,
      createTime: new Date(), // * 1
      updateTime: new Date(),
    };

    const p1 = firebase
      .database()
      .ref(Tables.Invitation)
      .child(emailStr)
      .child('pending')
      .child(groupId)
      .set(data);

    const p2 = firebase
      .database()
      .ref(Tables.GroupInvitation)
      .child(groupId)
      .child('pending')
      .child(emailStr)
      .set(data);

    return Promise.all([p1, p2]);
  }

  genCode(n: number): string {
    let str = '';

    for (let i = 0; i < n; i++) {
      str += Math.floor(Math.random() * 16).toString(16);
    }

    return str.toUpperCase();
  }

  invite(
    emailsArr: string[],
    meta: any,
    sender: any,
    groupId: string,
    code: string,
    role: string,
  ): Promise<any> {
    return Promise.all(
      emailsArr.map((email) => this.add(meta, sender, groupId, email, code, role)),
    );
  }

  respond(email: string, groupId: string, isAccept: any): Promise<any> {
    const emailStr = this.encodeEmail(email);
    const setInvitation = () => {
      const root = firebase.database().ref(Tables.Invitation).child(emailStr);

      return root
        .child('pending')
        .child(groupId)
        .once('value')
        .then(function (ss): any {
          if (!ss.exists()) return true;
          const data = ss.val();
          const op1 = root.child('pending').child(groupId).set(null);
          const op2 = root
            .child(isAccept ? 'accepted' : 'declined')
            .child(groupId)
            .set(
              Object.assign({}, data, {
                updateTime: new Date(),
              }),
            );
          const ops = [op1, op2];

          return Promise.all(ops);
        });
    };

    const setGroupInvitation = () => {
      const root = firebase.database().ref(Tables.GroupInvitation).child(groupId);
      return root
        .child('pending')
        .child(emailStr)
        .once('value')
        .then(function (ss): any {
          if (!ss.exists()) return true;
          const data = ss.val();
          const op1 = root.child('pending').child(emailStr).set(null);
          const op2 = root
            .child(isAccept ? 'accepted' : 'declined')
            .child(emailStr)
            .set(
              Object.assign({}, data, {
                updateTime: new Date(),
              }),
            );
          const ops = [op1, op2];
          return Promise.all(ops);
        });
    };

    return Promise.all([setInvitation(), setGroupInvitation()]);
  }

  respondAndJoinGroup({ uid, email, groupId, isAccept, role }: any) {
    const accepted = isAccept === '1' || isAccept === true;

    const ops = [() => this.respond(email, groupId, accepted)];
    const prepare = [];

    if (accepted) {
      prepare.push(() => {
        return this.groups.metaByGroupId(groupId).then((meta) => {
          return Promise.resolve(3);
          /*
            return this.groups.addUserToGroup(
              groupId,
              meta.name,
              uid,
              email,
              role || meta.newUserrole
            )
            */
        });
      });
    }

    return Promise.all(prepare.map((fn) => fn())).then(() => Promise.all(ops.map((fn) => fn())));
  }

  acceptInvitation(
    user: any,
    role: string,
    groupId: string,
    groupName: string,
    meta: any,
    sender: any,
  ): Promise<any> {
    return axios
      .post(API_BASE_URL + 'invitations', {
        user: user,
        role: role,
        groupId: groupId,
        groupName: groupName,
        meta: meta,
        sender: sender,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
