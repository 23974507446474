import { useSelector } from 'react-redux';

import { RootState } from '../store';

const useAuth = () => {
  const authenticated = useSelector((state: RootState) => state.auth.authenticated);
  const loading = useSelector((state: RootState) => state.auth.loading);
  const isAuthenticated = authenticated !== null && authenticated;

  return {
    loading,
    isAuthenticated,
  };
};

export default useAuth;
