import axios from 'axios';
import { getHeaders } from './CloudFunctions/helpers';

axios.defaults.baseURL = (
  process.env.REACT_APP_IS_LOCAL_CLOUD_FUNCTIONS === 'true'
    ? process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_LOCALHOST
    : process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS
) as string;

export interface UpgradeGroupInput {
  groupId: string;
  groupName: string;
  email: string;
  tokenId: string;
  plan: string;
}

export async function upgradeGroup(data: UpgradeGroupInput) {
  const res = await axios.post('/groupSubscribe', data, await getHeaders());

  return res.data;
}

export async function getCardDetails() {
  const res = await axios.get('/user/cardDetails', await getHeaders());

  return res.data;
}

export async function updateCardDetails(data: UpgradeGroupInput) {
  const res = await axios.post('/user/updateCard', data, await getHeaders());

  return res.data;
}

export async function cancelSubscription(groupId: string) {
  const res = await axios.post('/groupUnsubscribe', { groupId }, await getHeaders());
  return res.data;
}

export async function cancelTrial(groupId: string) {
  const res = await axios.post('/groupCancelTrial', { groupId }, await getHeaders());
  return res.data;
}
