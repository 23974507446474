import { createSlice } from '@reduxjs/toolkit';

interface State {
  authenticated: boolean | null;
  loading: boolean;
  signInLoading: boolean;
  errorMsg: string;
}

const initialState: State = {
  authenticated: null,
  loading: true,
  signInLoading: false,
  errorMsg: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authSuccess: (state) => {
      state.authenticated = true;
      state.loading = false;
      state.signInLoading = false;
    },
    signIn: (state, action: { payload: { email: string; password: string } }) => {
      state.signInLoading = true;
    },
    signOut: (state) => {
      state.signInLoading = true;
    },
    authFailed: (state, action) => {
      state.authenticated = false;
      state.loading = false;
      state.signInLoading = false;
      state.errorMsg = action.payload.errorMsg || '';
    },
    authVerify: (state) => {
      state.authenticated = null;
      state.loading = true;
      state.signInLoading = false;
    },
  },
});

export default authSlice;
