import axios, { AxiosRequestConfig } from 'axios';
import { getUserToken } from '../AuthService';

export async function getHeaders() {
  return {
    headers: {
      [`Authorization`]: `Bearer ${await getUserToken()}`,
    },
  } as AxiosRequestConfig;
}
