import axios, { AxiosResponse } from 'axios';
import { getHeaders } from './helpers';
import { axiosResponseHandler } from '../CloudFunctionsService';
import { IUserGroupData } from '@/common/common-types';

class User {
  /**
   * Get list of enterprise users
   */
  async getListEnterpriseUsers() {
    const response = axios.get('/getListEnterpriseUsers', await getHeaders());
    return axiosResponseHandler(response);
  }
  /**
   * Add enterprise user.
   */
  async addEnterpriseUser(email: string) {
    return axios
      .post('/addEnterpriseUser', { email }, await getHeaders())
      .then((res: AxiosResponse<{ data: any; error_code: number }>) => res)
      .catch((error) => {
        console.log(error);
      });
  }
  /**
   * Update end date for enterprise user
   */
  async updateEnterpriseEndDate(uid: string, enterprisePlanEndDate: string) {
    return axios
      .post('/updateEnterpriseEndDate', { uid, enterprisePlanEndDate }, await getHeaders())
      .then((res: AxiosResponse<{ data: any; error_code: number }>) => res)
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * Checks enterprise user
   */
  async isEnterpriseUser() {
    return axios
      .get('/isEnterpriseUser', await getHeaders())
      .then((res: AxiosResponse<{ data: any; error_code: number }>) => res.data?.data ?? false)
      .catch((error) => {
        console.log(error);
      });
  }

  async findUsersByGroups(ids: string[]): Promise<[IUserGroupData[], string][]> {
    return axios
      .get(`/findUsersByGroups?${ids.map((id) => `id=${id}`).join('&')}`, await getHeaders())
      .then((res: AxiosResponse<{ data: any; error_code: number }>) => res.data.data)
      .catch((error) => {
        console.log(error);
      });
  }
}

export default User;
