import { put, takeLatest } from 'redux-saga/effects';
import userSlice from './userSlice';
import notificationsSlice from '../notifications/notificationsSlice';
import cloudFunctionsInstance from '../../../services/CloudFunctionsService';
const FETCH_PAYMENT_METHODS = `${userSlice.name}/${userSlice.caseReducers.paymentMethodsFetch.name}`;
export const FETCH_IS_ENTERPRISE = `user/isEnterpriseFetch`;

/**
 * @param action: {
 *   payload: 'GroupId'
 * }
 */
export function* fetchPaymentMethods() {
  try {
    // @ts-ignore
    const res = yield cloudFunctionsInstance.userCard.list();
    yield put(userSlice.actions.paymentMethodsSuccess(res.data));
  } catch (e: any) {
    if (e?.response?.data?.message) {
      yield put(notificationsSlice.actions.set({ type: 'error', title: e.response.data.message }));
    }
  }
}

export function* fetchIsEnterpriseUser() {
  try {
    // @ts-ignore
    const res = yield cloudFunctionsInstance.user.isEnterpriseUser();
    yield put(userSlice.actions.setIsEnterpriseUser(res));
  } catch (e: any) {
    if (e?.response?.data?.message) {
      yield put(notificationsSlice.actions.set({ type: 'error', title: e.response.data.message }));
    }
  }
}

export function* userSaga() {
  yield takeLatest(FETCH_PAYMENT_METHODS, fetchPaymentMethods);
  yield takeLatest(FETCH_IS_ENTERPRISE, fetchIsEnterpriseUser);
}
