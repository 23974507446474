import { put, takeLatest, all, call } from 'redux-saga/effects';
import groupsSlice from './groupsSlice';
import { cancelSubscription, cancelTrial } from '../../../services/ApiService';
import notificationsSlice from '../notifications/notificationsSlice';
import { firebaseApi } from '../../../services/Firebase/firebase';
import { IUserData } from '../../../common/common-types';
import { getCurrentUser } from '@/services/AuthService';

const CANCEL_SUBSCRIPTION_TYPE = `${groupsSlice.name}/${groupsSlice.caseReducers.cancelSubscription.name}`;
const CANCEL_TRIAL_TYPE = `${groupsSlice.name}/${groupsSlice.caseReducers.cancelTrial.name}`;
const GROUPS_FETCH_WITH_META = `${groupsSlice.name}/${groupsSlice.caseReducers.groupsWithMetaFetch.name}`;

/**
 * @param action: {
 *   payload: 'GroupId'
 * }
 */
const GROUP = firebaseApi.groups;

function* getGroupMeta(id: string): Generator<any> {
  return yield call(GROUP.metaByGroupId, id);
}

export function* groupsMetaFetch(): Generator<any> {
  try {
    const currentUser = getCurrentUser();

    if (!currentUser?.email) {
      return;
    }

    // @ts-ignore
    const currentUserGr: IUserData['groups'] = yield firebaseApi.user.findUser();

    if (!currentUserGr?.groups) {
      return;
    }
    const { groups } = currentUserGr;
    const calls: any[] = [];
    Object.keys(groups).forEach((g) => {
      calls.push(getGroupMeta(g));
    });

    const response = yield all(calls);
    yield put(groupsSlice.actions.groupsWithMetaGot(response));
  } catch (e: any) {
    if (e && e.response.data.message) {
      yield put(notificationsSlice.actions.set({ type: 'error', title: e.response.data.message }));
    }
  }
}

export function* groupCancelSubscribe(action: any) {
  try {
    yield cancelSubscription(action.payload);
    yield put(groupsSlice.actions.groupsFetch());
  } catch (e: any) {
    if (e && e.response.data.message) {
      yield put(notificationsSlice.actions.set({ type: 'error', title: e.response.data.message }));
    }
  }
}

/**
 * @param action: {
 *   payload: 'GroupId'
 * }
 */
export function* groupCancelTrial(action: { type: string; payload: string }) {
  try {
    yield cancelTrial(action.payload);
    yield put(
      notificationsSlice.actions.set({ type: 'success', title: 'Group subscription cancelled' }),
    );
  } catch (e: any) {
    if (e && e.response.data.message) {
      yield put(notificationsSlice.actions.set({ type: 'error', title: e.response.data.message }));
    }
  }
}

export function* groupsSaga() {
  yield takeLatest(CANCEL_TRIAL_TYPE, groupCancelTrial);
  yield takeLatest(CANCEL_SUBSCRIPTION_TYPE, groupCancelSubscribe);
  yield takeLatest(GROUPS_FETCH_WITH_META, groupsMetaFetch);
}
