import firebase from 'firebase/app';
import 'firebase/database';
import { Tables } from '../../common/constants';

export default class Credentials {
  getEnterpriseUsersAllowList() {
    return firebase
      .database()
      .ref(Tables.Credentials + '/enterpriseUsersAllowList')
      .once('value')
      .then((ss) => {
        if (!ss.exists()) return [];
        return ss.val();
      });
  }
}
