import { createSlice } from '@reduxjs/toolkit';
import { IPaymentMethod } from '../../types';

const initialState = {
  paymentMethods: [] as IPaymentMethod[],
  isEnterpriseUser: undefined as boolean | undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsEnterpriseUser: (state, action) => {
      state.isEnterpriseUser = action.payload;
    },
    paymentMethodsFetch: (state) => {
      state.paymentMethods = [];
    },
    paymentMethodsSuccess: (state, action) => {
      state.paymentMethods = action.payload || [];
    },
  },
});

export default userSlice;
