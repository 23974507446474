import Grid from '@material-ui/core/Grid';
import Sidebar from '../../components/Sidebar';
import React from 'react';

const SidebarWrapper = ({ children }: any) => {
  return (
    <Grid container spacing={0} alignItems={'stretch'} className={'mh100'}>
      <Grid item xs={1} className={'bg-white'}>
        <Sidebar />
      </Grid>
      <Grid item xs={11} className={'bg-gray'}>
        {children}
      </Grid>
    </Grid>
  );
};

export default SidebarWrapper;
