import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  upgradeGroup: '',
  status: 'got',
  meta: {
    list: [],
    byId: {},
  },
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    groupsFetch: (state) => {
      state.status = 'fetch';
    },
    groupsWithMetaFetch: (state) => {
      state.status = 'fetch';
    },
    groupsWithMetaGot: (state, action) => {
      state.status = 'got';
      state.meta.list = action.payload;
      state.meta.byId = action.payload.reduce((acc: any, curr: any) => {
        return { ...acc, [curr.groupId]: curr };
      }, {});
    },
    groupsGot: (state, action) => {
      state.status = 'got';
      // @ts-ignore
      state.data = action.payload;
    },
    upgradeGroupSet: (state, action) => {
      state.upgradeGroup = action.payload;
    },
    cancelSubscription: (state, action) => state,
    cancelTrial: (state, action) => state,
  },
});

export default groupsSlice;
