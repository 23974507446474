import firebase from 'firebase/app';
import 'firebase/database';
import { Tables } from '../../common/constants';
import { IUserData } from '../../common/common-types';
import User from './user';

export default class GroupsUsersPermissions {
  user: User;
  constructor() {
    this.user = new User();
  }
  findGroupUsersByGroupId(groupId: string): Promise<IUserData[]> {
    return firebase
      .database()
      .ref(Tables.GroupUsers + '/' + groupId + '/users')
      .once('value')
      .then((ss) => {
        if (!ss.exists()) return null;
        const values = ss.val();
        const userIds = Object.keys(values);
        return this.user.findByIds(userIds);
      });
  }
  findAllGroupsUsers(groupIds: string[]): Promise<any> {
    return Promise.all(
      groupIds.map((groupId: string) => {
        return Promise.all([this.findGroupUsersByGroupId(groupId), Promise.resolve(groupId)]);
      }),
    );
  }
}
