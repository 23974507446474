/**
 * Components haven't to contain any business-logic.
 * They can contain only logic for switching view, handle callbacks
 * @constructor
 */
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PaymentIcon from '@material-ui/icons/Payment';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import AppsIcon from '@material-ui/icons/Apps';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import bookmarkLink from '../../icons/teamsyncbookmarks_logo.svg';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import cn from 'classnames';
import s from './styles.module.css';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import './index.scss';
import RouterUrlHelper from '../../routes/RouterUrlHelper';
import useEnterpriseAllowList from '../../hooks/useEnterpriseAllowList';
import authSlice from '../../store/features/auth/authSlice';

const IdentityIcon = <PermIdentityIcon />;
const BackIcon = <AssignmentReturnIcon />;

const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const { isHasPermissions } = useEnterpriseAllowList();

  const dispatch = useDispatch();
  const signOut = () => {
    dispatch(authSlice.actions.signOut());
  };

  const redirectToProfile = () => {
    history.push(RouterUrlHelper.urlMap.profile.path);
  };
  const redirectToEnterpriseUsers = () => {
    history.push(RouterUrlHelper.urlMap.enterpriseUsers.path);
  };

  const redirectToPlans = () => {
    history.push(RouterUrlHelper.urlMap.plans.path);
  };

  const redirectToBookmarks = () => {
    history.push(RouterUrlHelper.urlMap.groups.path);
  };

  const redirectToHome = () => {
    history.push(RouterUrlHelper.urlMap.root.path);
  };

  const sidebarItems = useMemo(() => {
    const base = [
      {
        path: RouterUrlHelper.urlMap.groups.path,
        onClick: redirectToBookmarks,
        icon: <AppsIcon />,
      },
      {
        path: RouterUrlHelper.urlMap.plans.path,
        onClick: redirectToPlans,
        icon: <PaymentIcon />,
      },
      {
        path: RouterUrlHelper.urlMap.profile.path,
        onClick: redirectToProfile,
        icon: IdentityIcon,
      },
    ];

    if (isHasPermissions) {
      base.push({
        path: RouterUrlHelper.urlMap.enterpriseUsers.path,
        onClick: redirectToEnterpriseUsers,
        icon: <AdminPanelSettingsOutlinedIcon />,
      });
    }
    return base;
  }, [isHasPermissions]);

  return (
    <Grid className={cn([s.paper])}>
      <List className={cn([s.list])}>
        <ListItem button onClick={redirectToHome}>
          <img src={bookmarkLink} className={classNames(['color-primary', s.logo])} alt={'Home'} />
        </ListItem>
        <Divider />
        {sidebarItems.map((i) => {
          return (
            <ListItem
              key={i.path}
              button
              onClick={i.onClick}
              className={classNames(s.listItem, { selected: location.pathname === i.path })}
            >
              <ListItemAvatar className={classNames(['color-primary', s.listItemAvatar])}>
                {i.icon}
              </ListItemAvatar>
            </ListItem>
          );
        })}
        <div className={cn([s.buttonLogoutWrap])}>
          <ListItem button onClick={signOut} className={s.listItem}>
            <ListItemAvatar className={classNames(['color-primary', s.listItemAvatar])}>
              {BackIcon}
            </ListItemAvatar>
          </ListItem>
        </div>
      </List>
    </Grid>
  );
};

export default Sidebar;
